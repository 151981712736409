import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/build/cjs/http.impl';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { PlanListWidgetRole } from '../../constants/elements';
import { plansFixtureTranslated } from '../../fixtures';
import { uncompressUuidArray } from '../../services/uuid-compression';
import { WarmupData } from '../../services/WarmupData';
import { toError } from '../../utils/errors';
import { CtaClickHandler } from '../Plan/viewer.controller';
import model, { PlanListWidgetProps } from './model';

export type PlanVariantState = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.PlanVariantDefaultState
  | PlanListWidgetRole.PlanVariantHighlightedState
  | PlanListWidgetRole.PlanVariantCustomState
>;

type PlanVariantRole = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.PlanVariantDefaultWidget
  | PlanListWidgetRole.PlanVariantHighlightedWidget
  | PlanListWidgetRole.PlanVariantCustomWidget
>;

type PlanListWidgetState = Extract<
  PlanListWidgetRole,
  | PlanListWidgetRole.EmptyState
  | PlanListWidgetRole.PlansInfoState
  | PlanListWidgetRole.LoadingState
  | PlanListWidgetRole.ErrorState
>;

interface RepeaterItemData {
  _id: string;
  plan: PublicPlan;
}

const PLAN_VARIANT_STATE_TO_WIDGET_VARIANT_ID: { [key in PlanVariantState]: `#${PlanVariantRole}` } = {
  [PlanListWidgetRole.PlanVariantDefaultState]: `#${PlanListWidgetRole.PlanVariantDefaultWidget}`,
  [PlanListWidgetRole.PlanVariantHighlightedState]: `#${PlanListWidgetRole.PlanVariantHighlightedWidget}`,
  [PlanListWidgetRole.PlanVariantCustomState]: `#${PlanListWidgetRole.PlanVariantCustomWidget}`,
};

export default model.createController(({ $w, $widget, flowAPI, controllerConfig }) => {
  const warmUpData = new WarmupData(controllerConfig.compId, controllerConfig.wixCodeApi, flowAPI);

  let externalOnSelectHandler: CtaClickHandler | undefined;
  let autoLoadPlans = true;
  const getDemoPlansIfEditor = () => {
    return flowAPI.environment.isEditor ? plansFixtureTranslated(flowAPI.translations.t) : null;
  };

  const getOrderedPlans = async (planIds?: string[]) => {
    if (!planIds?.length) {
      return getDemoPlansIfEditor();
    }

    const response = flowAPI.experiments.enabled(TPA_EXPERIMENTS.USE_WARM_UP_DATA_BLOCKS)
      ? await warmUpData.cache('plan.list', () => flowAPI.httpClient.request(listPublicPlans({ planIds })))
      : await flowAPI.httpClient.request(listPublicPlans({ planIds }));

    return response.data.plans
      ? response.data.plans.sort((a, b) => (planIds.indexOf(a.id!) > planIds.indexOf(b.id!) ? 1 : -1))
      : getDemoPlansIfEditor();
  };

  const showState = (state: PlanListWidgetState) => {
    $w(`#${PlanListWidgetRole.PlanListStates}`).changeState(state);
  };

  const setWidgetData = (
    plans: PublicPlan[],
    props: Pick<PlanListWidgetProps, 'customStylePlanIds' | 'highlightedPlanIds'>,
  ) => {
    const highlightedPlanIds = getUncompressedPlanIds(props.highlightedPlanIds);
    const customStylePlanIds = getUncompressedPlanIds(props.customStylePlanIds);

    $w(`#${PlanListWidgetRole.PlanList}`).onItemReady(($item, { plan }: RepeaterItemData) =>
      updatePlanItem($item, { plan, highlightedPlanIds, customStylePlanIds }),
    );

    $w(`#${PlanListWidgetRole.PlanList}`).data = plans.map(
      (plan) =>
        ({
          _id: plan.id!,
          plan,
        } satisfies RepeaterItemData),
    );

    showState(PlanListWidgetRole.PlansInfoState);
  };

  const updateDisplayedPlans = async (newProps: PlanListWidgetProps) => {
    const planIds = getUncompressedPlanIds(newProps.planIds);
    const plans = await getOrderedPlans(planIds);
    if (plans) {
      setWidgetData(plans, newProps);
    }
  };

  const updatePlanStyleVariants = async (newProps: PlanListWidgetProps) => {
    const highlightedPlanIds = getUncompressedPlanIds(newProps.highlightedPlanIds);
    const customStylePlanIds = getUncompressedPlanIds(newProps.customStylePlanIds);
    $w(`#${PlanListWidgetRole.PlanList}`).forEachItem(($item, { plan }: RepeaterItemData) =>
      updatePlanItem($item, { plan, highlightedPlanIds, customStylePlanIds }),
    );
  };

  $widget.onPropsChanged((oldProps, newProps) => {
    if (hasDisplayedPlansChanged(oldProps, newProps)) {
      updateDisplayedPlans(newProps);
    } else if (hasPlanStyleVariantChanged(oldProps, newProps)) {
      updatePlanStyleVariants(newProps);
    }
  });

  const updatePlanItem = (
    $item: $w.$w,
    data: {
      plan: PublicPlan;
      highlightedPlanIds?: string[];
      customStylePlanIds?: string[];
    },
  ) => {
    const { plan, highlightedPlanIds, customStylePlanIds } = data;
    const state = getPlanWidgetState({ planId: plan.id!, highlightedPlanIds, customStylePlanIds });
    $item(`#${PlanListWidgetRole.PlanVariantBox}`).changeState(state);
    const planId = PLAN_VARIANT_STATE_TO_WIDGET_VARIANT_ID[state];
    $item(planId).setPlan(plan);
    $item(planId).registerCtaHandler(plan);
    if (externalOnSelectHandler) {
      $item(planId).onSelect(externalOnSelectHandler);
    }
  };

  const initializeRepeaterItems = (planIds: string[] | undefined) => {
    if (!planIds?.length) {
      return;
    }
    /*
      Set plan ids on repeater to initialize repeater items and call
      `pageReady` on inner widgets. This will prevent an issue, where
      repeater's `onItemReady` is triggered before `pageReady` in inner widget
    */
    return new Promise((resolve) => {
      $w(`#${PlanListWidgetRole.PlanList}`).onItemReady(resolve);
      $w(`#${PlanListWidgetRole.PlanList}`).data = planIds?.map((id) => ({ _id: id })) ?? [];
    });
  };

  return {
    pageReady: async () => {
      const isWidgetRendered = $w(`#${PlanListWidgetRole.PlanList}`).rendered;
      if (!autoLoadPlans || (flowAPI.environment.isEditor && !isWidgetRendered)) {
        return;
      }

      if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
        showState(PlanListWidgetRole.LoadingState);
      }

      try {
        const planIds = getUncompressedPlanIds($widget.props.planIds);
        await initializeRepeaterItems(planIds);
        const plans = await getOrderedPlans(planIds);
        if (!plans?.length) {
          return showState(PlanListWidgetRole.EmptyState);
        }

        setWidgetData(plans, $widget.props);
      } catch (e) {
        showState(PlanListWidgetRole.ErrorState);
        flowAPI.errorMonitor?.captureException(toError(e));
      }
    },
    exports: {
      onSelect: (cb: CtaClickHandler) => {
        externalOnSelectHandler = cb;
      },
      setPlans: async (planIds: string[]) => {
        autoLoadPlans = false;
        await initializeRepeaterItems(planIds);
        const plans = await getOrderedPlans(planIds);
        if (plans?.length) {
          setWidgetData(plans, $widget.props);
        } else {
          showState(PlanListWidgetRole.EmptyState);
        }
      },
      setTitle: (title: string) => {
        $w(`#${PlanListWidgetRole.Title}`).text = title;
      },
      setSubtitle: (subtitle: string) => {
        $w(`#${PlanListWidgetRole.Subtitle}`).text = subtitle;
      },
    },
  };
});

function getUncompressedPlanIds(planIdsProp?: string): string[] | undefined {
  return planIdsProp ? uncompressUuidArray(planIdsProp.split(',')).filter((id) => id !== '') : undefined;
}

function getPlanWidgetState(params: {
  planId: string;
  highlightedPlanIds?: string[];
  customStylePlanIds?: string[];
}): PlanVariantState {
  const { planId, highlightedPlanIds, customStylePlanIds } = params;
  if (highlightedPlanIds?.includes(planId)) {
    return PlanListWidgetRole.PlanVariantHighlightedState;
  }

  if (customStylePlanIds?.includes(planId)) {
    return PlanListWidgetRole.PlanVariantCustomState;
  }

  return PlanListWidgetRole.PlanVariantDefaultState;
}

function hasDisplayedPlansChanged(oldProps: PlanListWidgetProps, newProps: PlanListWidgetProps): boolean {
  return oldProps.planIds !== newProps.planIds;
}

function hasPlanStyleVariantChanged(oldProps: PlanListWidgetProps, newProps: PlanListWidgetProps): boolean {
  return (
    oldProps.customStylePlanIds !== newProps.customStylePlanIds ||
    oldProps.highlightedPlanIds !== newProps.highlightedPlanIds
  );
}
